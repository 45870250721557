<template>
    <div class="productCommunList">
        <el-page-header @back="backPrivious" content="产品供应"></el-page-header>
        <div class="breadcrumb">
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/CommunityIndex' }">社区</el-breadcrumb-item>
                <el-breadcrumb-item>产品供应</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="f-c-e mt-30">
            <img class="publishBtn" @click="topublish" src="@/assets/Community/IndustryTrends/publishBtn.png" alt="">
        </div>
        <div class="list" v-if="productCommunList && productCommunList.length">
            <div class="Item flex" v-for="(item, index) of productCommunList" :key="index"
                @click="toproductCommunDetail(item.postId)">
                <div class="img">
                    <img class="img" :src="item.userAvatarUrl" alt="">
                </div>
                <div class="info">
                    <div class="flex mb-30">
                        <div class="userName">{{ item.userName }}</div>
                        <div class="createDate">{{ item.createDate }}</div>
                    </div>
                    <div class="postHead">{{ item.postHead }}</div>
                    <div class="previewContent">{{ item.previewContent }}</div>
                    <div v-if="item.mobject" class="mobject">
                        <img :src="item.mobject" alt="">
                    </div>
                    <div class="comments f-c">
                        <img src="@/assets/Community/IndustryTrends/comment.png" alt="">
                        {{ item.comments }}条评论
                    </div>
                </div>

            </div>
        </div>

        <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
            @pagination="fetchData" />
    </div>
</template>

<script>


import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';

export default {
    name: 'productCommunList',
    mixins: [PaginationRetention],
    data() {
        return {
            productCommunList: [],
        }
    },
    created() {
        this.findForumMediumState();
    },
    methods: {
        getDate() {
            this.forumArticleList(this.paging, this.pagingSize,);
        },
        // 获取产品交流帖子列表
        forumArticleList(page = this.page, size = this.size) {
            let that = this;
            that.$http.forumArticleList({
                postUserId: parseInt(that.$store.state.userId),
                page,
                size
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data.records;
                    that.productCommunList = records;
                    that.currentPage = res.data.current;//当前页数
                    that.total = res.data.total;
                }
            })
        },
        //  跳转到帖子详情  productCommunList
        toproductCommunDetail(postId) {
            console.log(postId)
            this.$router.push({
                name: 'productCommunDetail', query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        postId,
                    }))
                }
            })
        },
        // 获取媒体信息
        findForumMediumState() {
            let that = this;
            that.$http.findForumMediumState({
                userId: parseInt(that.$store.state.userId)
            }).then(res => {
                if (res.code == 200) {
                    let {
                        mediumState
                    } = res.data,
                        MediumStateInfo = res.data;
                    switch (mediumState) {
                        case '00': //无媒体信息
                            that.AuthenticationList = that.NoCertifiedAuthenticationList
                            MediumStateInfo.mediumStateText = '未认证'
                            MediumStateInfo.mediumClass = 'bg-FCC1C4 col-F4333C'
                            break;
                        case '10':
                            that.AuthenticationList = that.CertifiedAuthenticationList
                            break;
                        case '20': //审核申请
                        case '21': //审核申请失败
                            that.AuthenticationList = that.NoCertifiedAuthenticationList
                            MediumStateInfo.mediumStateText = '申请不通过'
                            MediumStateInfo.mediumClass = 'bg-FCC1C4 col-F4333C'
                            break;
                        case '30': //审核修改
                            that.AuthenticationList = that.NoCertifiedAuthenticationList
                            MediumStateInfo.mediumStateText = '认证审核中'
                            MediumStateInfo.mediumClass = 'bg-FCDAB2 col-F48400'
                            break;

                        case '31': //审核修改失败
                            that.AuthenticationList = that.NoCertifiedAuthenticationList
                            MediumStateInfo.mediumStateText = '审核不通过'
                            MediumStateInfo.mediumClass = 'bg-FCC1C4 col-F4333C'
                            break;
                    }
                    that.MediumStateInfo = MediumStateInfo;
                    that.$setStorage('MediumStateInfo', MediumStateInfo);

                }
            })
        },
        // 
        topublish() {
            this.$router.push({
                name: 'productCommunPost'
            });
        },

    },
}

</script>
<style lang='less' scoped>
.productCommunList {
    .breadcrumb {
        padding: 10px 0;
        border-bottom: 1px solid #FBFBFB;
    }

    .el-breadcrumb ::v-deep .el-breadcrumb__inner {
        color: #666666 !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
        color: #0363FA !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .publishBtn {
        cursor: pointer;
    }

    .list {
        margin-top: 30px;

        .Item {
            border-radius: 20px;
            background: #F8F8F8;
            padding: 30px 75px;
            margin-bottom: 20px;
            cursor: pointer;

            .img {
                height: 55px;
                width: 55px;
                border-radius: 50%;
                margin-right: 15px;

            }

            .info {
                .userName {
                    font-size: 20px;
                    color: #333333;
                    line-height: 20px;
                }

                .createDate {
                    margin-left: 10px;
                    font-size: 16px;
                    color: #999999;
                    line-height: 20px;
                }

                .postHead {
                    font-weight: 500;
                    font-size: 24px;
                    color: #333333;
                    margin-bottom: 15px;
                }

                .previewContent {
                    font-size: 16px;
                    color: #666666;
                    margin-bottom: 15px;
                }

                .mobject {
                    margin-bottom: 20px;

                    img {
                        border-radius: 20px;
                        height: 100px;
                        width: 100px;
                    }

                }

                .comments {
                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 8px;
                    }

                    font-size: 16px;
                    color: #999999;
                }
            }
        }
    }
}
</style>